import React, { useState } from 'react';

const DoctorRegistration = () => {
    const [doctorData, setDoctorData] = useState({
        profilePicture: '',
        name: '',
        registrationNumber: '',
        qualification: '',
        specialization: '',
        clinics: [
            { name: '', address: '', consultationFees: '', contactNumber: '' }
        ],
        email: '',
        medicalCouncil: ''
    });

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedClinics = [...doctorData.clinics];
        updatedClinics[index][name] = value;
        setDoctorData({ ...doctorData, clinics: updatedClinics });
    };

    const addClinic = () => {
        setDoctorData({
            ...doctorData,
            clinics: [...doctorData.clinics, { name: '', address: '', consultationFees: '', contactNumber: '' }]
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(doctorData);
    };

    return (
        <div className="container mx-auto mt-8">
            <div className="max-w-lg mx-auto">
                <h1 className="mb-4 text-3xl font-semibold">Doctor's Profile Registration</h1>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="profilePicture" className="block mb-2">Doctor's Profile Picture</label>
                        <input type="file" id="profilePicture" name="profilePicture" className="w-full px-4 py-2 border border-gray-300 rounded-md" />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="name" className="block mb-2">Doctor's Name</label>
                        <input type="text" id="name" name="name" className="w-full px-4 py-2 border border-gray-300 rounded-md" value={doctorData.name} onChange={(e) => setDoctorData({ ...doctorData, name: e.target.value })} />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="registrationNumber" className="block mb-2">Registration Number</label>
                        <input type="text" id="registrationNumber" name="registrationNumber" className="w-full px-4 py-2 border border-gray-300 rounded-md" value={doctorData.registrationNumber} onChange={(e) => setDoctorData({ ...doctorData, registrationNumber: e.target.value })} />
                    </div>
                    {/* Add other input fields for doctor's details */}
                    {doctorData.clinics.map((clinic, index) => (
                        <div key={index} className="mb-4">
                            <h2 className="mb-2 text-lg font-semibold">Clinic {index + 1}</h2>
                            <input type="text" name="name" placeholder="Clinic Name" className="w-full px-4 py-2 mb-2 border border-gray-300 rounded-md" value={clinic.name} onChange={(e) => handleInputChange(e, index)} />
                            <input type="text" name="address" placeholder="Clinic Address" className="w-full px-4 py-2 mb-2 border border-gray-300 rounded-md" value={clinic.address} onChange={(e) => handleInputChange(e, index)} />
                            <input type="text" name="consultationFees" placeholder="Consultation Fees" className="w-full px-4 py-2 mb-2 border border-gray-300 rounded-md" value={clinic.consultationFees} onChange={(e) => handleInputChange(e, index)} />
                            <input type="text" name="contactNumber" placeholder="Contact Number" className="w-full px-4 py-2 mb-2 border border-gray-300 rounded-md" value={clinic.contactNumber} onChange={(e) => handleInputChange(e, index)} />
                        </div>
                    ))}
                    <button type="button" className="px-4 py-2 mb-4 text-white bg-blue-500 rounded-md" onClick={addClinic}>Add Clinic</button>
                    <div className="mb-4">
                        <button type="submit" className="px-4 py-2 text-white bg-blue-500 rounded-md">Register</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default DoctorRegistration;
