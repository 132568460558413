import React, { useState } from 'react';

const BookAppointment = ({ clinicName, availableDays, availableTimes, patientDetails }) => {
    // Dummy patient data
    const dummyPatientDetails = {
        fullName: 'John Doe',
        age: 30,
        gender: 'Male',
        email: 'johndoe@example.com',
        phoneNumber: '123-456-7890',
        address: '123 Main St, City, Country'
    };

    const [confirmed, setConfirmed] = useState(false);

    const handleAppointmentConfirmation = () => {
       
        setConfirmed(true);

        setTimeout(() => {
            window.location.href='/appointment-confirmation';
        }, 2000);
    };

    return (
        <div className="container mx-auto mt-8">
            <div className="max-w-lg mx-auto">
                <h1 className="mb-4 text-3xl font-semibold">Book Appointment</h1>
                <h2 className="mb-2 text-lg font-semibold">Clinic's Location</h2>
                <p>{clinicName}</p>
                <h2 className="mb-2 text-lg font-semibold">Available Time</h2>
                <p>{availableDays}</p>
                <p>{availableTimes}</p>
                <h2 className="mb-2 text-lg font-semibold">Patient Details</h2>
                <div className="mb-4">
                    <input type="text" placeholder="Full Name" className="w-full px-4 py-2 mb-2 border border-gray-300 rounded-md" defaultValue={dummyPatientDetails.fullName} />
                </div>
                <button onClick={handleAppointmentConfirmation} className="px-4 py-2 text-white bg-blue-500 rounded-md">Set Appointment</button>
                {confirmed && <p>Appointment set successfully!</p>}
            </div>
        </div>
    );
};

export default BookAppointment;
