import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

const DoctorProfile = ({ name, specialty, clinic, consultationTime, consultationFee }) => {
    // Dummy data
    const dummyData = {
        name: "Dr. John Doe",
        specialty: "Cardiology",
        clinic: "ABC Hospital",
        consultationTime: "Monday to Friday, 9:00 AM - 5:00 PM",
        consultationFee: 500
    };

    const { name: dummyName, specialty: dummySpecialty, clinic: dummyClinic, consultationTime: dummyConsultationTime, consultationFee: dummyConsultationFee } = dummyData;

    const doctorName = name || dummyName;
    const doctorSpecialty = specialty || dummySpecialty;
    const doctorClinic = clinic || dummyClinic;
    const doctorConsultationTime = consultationTime || dummyConsultationTime;
    const doctorConsultationFee = consultationFee || dummyConsultationFee;

    const [submitted, setSubmitted] = useState(false);

    const handleBooking = () => {
       
        setSubmitted(true);

        // Redirect to confirmation page after 2 seconds
        setTimeout(() => {
            window.location.href='/confirmation-page';
        }, 2000);
    };

    return (
        <div className="container mx-auto mt-8">
            <div className="max-w-lg mx-auto">
                <h1 className="mb-4 text-3xl font-semibold">{doctorName}</h1>
                <p>{doctorSpecialty}</p>
                <p>Clinic: {doctorClinic}</p>
                <p>Consultation Time: {doctorConsultationTime}</p>
                <p>Consultation Fee: {doctorConsultationFee} Rs</p>
                {/* Button to book appointment */}
                <button onClick={handleBooking} className="px-4 py-2 text-white bg-blue-500 rounded-md">Book Appointment</button>
                {/* Show confirmation message */}
                {submitted && <p>Appointment booked successfully!</p>}
            </div>
        </div>
    );
};

export default DoctorProfile;
