import { Link } from "react-router-dom";

const ClinicCard = ({ name, specialty, patients, rating, location }) => {
    return (
        <div className="p-4 mb-4 border border-gray-300">
            <p className="font-semibold">{name}</p>
            <p>{specialty}</p>
            <p>{patients} Patients</p>
            <p>⭐️ {rating} Ratings</p>
            <p>{location}</p>
            <Link to={`/clinics/${name}`} className="text-blue-500">View Details</Link>
        </div>
    );
};

export default ClinicCard;
