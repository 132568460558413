import React from 'react';

const Confirmation = () => {
    return (
        <div className="container mx-auto mt-8">
            <div className="max-w-lg mx-auto">
                <h1 className="mb-4 text-3xl font-semibold">Appointment Confirmed</h1>
                <p>Your appointment has been successfully set.</p>
                <p>Thank you for choosing our service!</p>
            </div>
        </div>
    );
};

export default Confirmation;
