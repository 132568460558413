import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { About, Contact, Doctor, DoctorDetails, Footer, Header, Home, Landing, Services } from "./Router";
import { useAuth0 } from "@auth0/auth0-react";
import Profile from "./pages/Profile";
import FindADoctor from "./pages/individuals/FindADoctor";
import ClinicCard from "./pages/hospital/ClinicCard";
import ClinicDetails from "./pages/hospital/ClinicDetails";
import BookAppointment from "./pages/hospital/BookAppointment";
import DoctorProfile from "./pages/hospital/DoctorProfile";
import Confirmation from './pages/hospital/Confirmation';
import DoctorRegistration from './pages/doctor/DoctorRegistration';

function App() {
  const { isAuthenticated } = useAuth0();

  return (
    <div>
      <Router>
        <Header />
        <Routes>
          {isAuthenticated ? (
            <>
              <Route path="/" element={<Home />} exact />
              <Route path="/about" element={<About />} exact />
              <Route path="/services" element={<Services />} exact />
              <Route path="/contact" element={<Contact />} exact />
              <Route path="/doctors" element={<Doctor />} exact />
              <Route path="/doctors/:id" element={<DoctorProfile />} exact />
              <Route path="/profile" element={<Profile />} exact />
              <Route path="/find-a-doctor" element={<FindADoctor />} exact />
              <Route path="/clinics" element={<ClinicCard />} />
              <Route path="/clinics/:name" element={<ClinicDetails />} />
              <Route path="/book-appointment/:clinicName" element={<BookAppointment />} />
              <Route path='/appointment-confirmation' element=<Confirmation /> exact />
              <Route path='/doctor-registration' element=<DoctorRegistration/> exact/>
            </>
          ) : (
            <Route path="/" element={<Landing />} exact />
          )}
          <Route path="*" element={<Landing />} exact />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
