import React from 'react';
import ClinicCard from '../hospital/ClinicCard';

const FindADoctor = () => {
    // Dummy clinic data
    // Updated static data for clinics
    const clinics = [
        {
            id: 1,
            name: 'Basantapur more, Arambagh',
            specialty: 'Pediatric Clinics',
            patients: '1000+',
            rating: '4.5',
            location: '123, Basantapure more, Arambagh Hooghly - 712016, West Bengal, India.'
        },
        {
            id: 2,
            name: 'Ulubaria Bus stand, Ulubaria',
            specialty: 'Pediatric Clinics',
            patients: '1000+',
            rating: '4.3',
            location: 'Ulubaria Bus stand, Ulubaria'
        }
    ];

    return (
        <div className="container mx-auto mt-8">
            <div className="max-w-lg mx-auto">
                <h1 className="mb-4 text-3xl font-semibold">Find a Doctor</h1>
                <h2 className="mb-2 text-lg font-semibold">Clinics</h2>
                {clinics.map(clinic => (
                    <ClinicCard
                        key={clinic.id}
                        name={clinic.name}
                        specialty={clinic.specialty}
                        patients={clinic.patients}
                        rating={clinic.rating}
                        location={clinic.location}
                    />
                ))}
            </div>
        </div>
    );
};

export default FindADoctor;
