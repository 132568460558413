import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="py-8 text-white bg-gray-800">
            <div className="container mx-auto">
                <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                    {/* About Us */}
                    <div>
                        <h3 className="mb-4 text-xl font-semibold">About Us</h3>
                        <p className="text-gray-300">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet odio nec elit iaculis posuere.</p>
                    </div>
                    {/* Contact Us */}
                    <div>
                        <h3 className="mb-4 text-xl font-semibold">Contact Us</h3>
                        <p className="text-gray-300">123 Street Name, City, Country</p>
                        <p className="text-gray-300">Phone: +123 456 7890</p>
                        <p className="text-gray-300">Email: info@example.com</p>
                    </div>
                    {/* Quick Links */}
                    <div>
                        <h3 className="mb-4 text-xl font-semibold">Quick Links</h3>
                        <ul>
                            <li><Link to="/" className="text-gray-300 hover:text-white">Home</Link></li>
                            <li><Link to="/find-a-doctor" className="text-gray-300 hover:text-white">Find a Doctor</Link></li>
                            <li><Link to="/services" className="text-gray-300 hover:text-white">Services</Link></li>
                            <li><Link to="/contat" className="text-gray-300 hover:text-white">Contact</Link></li>
                            <li><Link to="/about" className="text-gray-300 hover:text-white">About</Link></li>
                        </ul>
                    </div>
                </div>
                {/* Copyright */}
                <div className="mt-8 text-center">
                    <p className="text-gray-300">&copy; {new Date().getFullYear()} WhiteCoatLink. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;