import React from 'react';
import { Link } from 'react-router-dom';

const ClinicDetails = ({ name, location, doctors }) => {
    // Dummy doctor data
    const dummyDoctors = [
        {
            id: 1,
            name: 'Dr. Ananya Singha',
            specialty: 'MBBS, MD Pediatrics',
            consultationFee: 300
        },
        {
            id: 2,
            name: 'Dr. Dipesh Barman',
            specialty: 'MBBS, MD Pediatrics',
            consultationFee: 300
        },
        {
            id: 3,
            name: 'Dr. Aritra Khan',
            specialty: 'MBBS, MD Pediatrics',
            consultationFee: 300
        }
    ];

    return (
        <div className="container mx-auto mt-8 p-7">
            <div className="max-w-lg mx-auto">
                <h1 className="mb-4 text-3xl font-semibold">{name}</h1>
                <h2 className="mb-2 text-lg font-semibold">Clinic's Location</h2>
                <p>{location}</p>
                <h2 className="mb-2 text-lg font-semibold">Doctor's List</h2>
                {dummyDoctors.map((doctor, index) => (
                    <div key={index} className="p-4 mb-4 border border-gray-300">
                        <p className="font-semibold">{doctor.name}</p>
                        <p>{doctor.specialty}</p>
                        <p>{doctor.consultationFee} Rs</p>
                        <Link to={`/doctors/${doctor.id}`} className="text-blue-500">View Profile</Link>
                    </div>
                ))}

                <div className='p-4'>
                    <Link to={`/book-appointment/${name}`} className="px-4 py-2 text-white bg-blue-500 rounded-md">Book Appointment</Link>
                </div>
            </div>
        </div>
    );
};

export default ClinicDetails;
