import React from 'react';
import { Link } from 'react-router-dom';
import { FiSearch, FiCalendar, FiFileText, FiBell } from 'react-icons/fi';
import { useAuth0 } from '@auth0/auth0-react';

const Landing = () => {
    const { loginWithPopup, user, isAuthenticated, logout } = useAuth0();
    
    return (
        <div className="container px-4 py-8 mx-auto">
            <div className="max-w-3xl mx-auto text-center">
                <h1 className="mb-4 text-4xl font-bold text-gray-900">Welcome to WhiteCoatLink</h1>
                <p className="mb-6 text-lg text-gray-700">Your All-in-One Healthcare Solution</p>
                <div className="flex flex-wrap justify-center mb-6">
                    <div className="w-full px-4 mb-6 md:w-1/2 lg:w-1/4">
                        <div className="p-6 transition duration-300 bg-white rounded-lg shadow-md hover:shadow-lg">
                            <FiSearch className="mx-auto mb-4 text-4xl text-blue-500" />
                            <h3 className="mb-2 text-lg font-semibold text-gray-900">Find Doctors</h3>
                            <p className="text-sm text-gray-700">Discover trusted doctors and clinics in your area.</p>
                        </div>
                    </div>
                    <div className="w-full px-4 mb-6 md:w-1/2 lg:w-1/4">
                        <div className="p-6 transition duration-300 bg-white rounded-lg shadow-md hover:shadow-lg">
                            <FiCalendar className="mx-auto mb-4 text-4xl text-blue-500" />
                            <h3 className="mb-2 text-lg font-semibold text-gray-900">Book Appointments</h3>
                            <p className="text-sm text-gray-700">Easily schedule appointments online.</p>
                        </div>
                    </div>
                    <div className="w-full px-4 mb-6 md:w-1/2 lg:w-1/4">
                        <div className="p-6 transition duration-300 bg-white rounded-lg shadow-md hover:shadow-lg">
                            <FiFileText className="mx-auto mb-4 text-4xl text-blue-500" />
                            <h3 className="mb-2 text-lg font-semibold text-gray-900">Access Records</h3>
                            <p className="text-sm text-gray-700">View and manage your medical records securely.</p>
                        </div>
                    </div>
                    <div className="w-full px-4 mb-6 md:w-1/2 lg:w-1/4">
                        <div className="p-6 transition duration-300 bg-white rounded-lg shadow-md hover:shadow-lg">
                            <FiBell className="mx-auto mb-4 text-4xl text-blue-500" />
                            <h3 className="mb-2 text-lg font-semibold text-gray-900">Receive Reminders</h3>
                            <p className="text-sm text-gray-700">Get timely reminders for your appointments.</p>
                        </div>
                    </div>
                </div>
                <p className="mb-6 text-lg text-gray-700">Join us today and experience the future of healthcare!</p>
                <button onClick={() => loginWithPopup()} className="inline-block px-6 py-3 text-lg text-white transition duration-300 bg-blue-500 rounded-md hover:bg-blue-600">Sign Up Now</button>
            </div>
        </div>
    );
};

export default Landing;
