import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Profile = () => {
    const { user, isAuthenticated } = useAuth0();
    const userImage = user.picture;

    // Static treatment information (for demonstration purposes)
    const [treatmentInfo, setTreatmentInfo] = useState({
        sex: 'Female',
        age: 21,
        blood: 'Normal',
        lastCheckup: '2023-12-15',
        numberOfCheckup: 3,
        patientName: user.name,
        patientID: 'xxxxxxx370',
        systolicBP: 123,
        diastolicBP: 82,
        weight: 80.0,
        systolicBPAnalysis: {
            low: 53,
            normal: 35,
            high: 20
        }
    });

    // State for editing mode
    const [isEditing, setIsEditing] = useState(false);

    // Handler for saving edited information
    const handleSave = () => {
        // Implement logic to save updated treatment information
        setIsEditing(false);
    };

    return (
        isAuthenticated && (
            <div className="container p-3 mx-auto mt-8">
                <div className="max-w-lg mx-auto overflow-hidden bg-white rounded-lg shadow-md">
                    <div className="p-4">
                        <h1 className="mb-2 text-xl font-semibold">User Profile</h1>
                        <div className="flex items-center space-x-4">
                            <img src={user.picture || userImage} alt={user.name} className="w-16 h-16 rounded-full" />
                            <div>
                                <h2 className="text-lg font-semibold">{user.name}</h2>
                                <p className="text-gray-500">{user.email}</p>
                            </div>
                        </div>
                        {/* Treatment Information */}
                        <div className="mt-4">
                            <h3 className="mb-2 text-lg font-semibold">Treatment Information</h3>
                            {isEditing ? (
                                <div>
                                    {/* Input fields for editing */}
                                    {/* Example: */}
                                    <input type="text" value={treatmentInfo.sex} onChange={(e) => setTreatmentInfo({ ...treatmentInfo, sex: e.target.value })} />
                                    {/* Add more input fields as needed */}
                                    <button onClick={handleSave} className="px-3 py-1 mt-2 text-white bg-blue-500 rounded-md">Save</button>
                                </div>
                            ) : (
                                <div>
                                    {/* Display fields */}
                                    <p><span className="font-semibold">Sex:</span> {treatmentInfo.sex}</p>
                                    <p><span className="font-semibold">Age:</span> {treatmentInfo.age}</p>
                                    <p><span className="font-semibold">Blood:</span> {treatmentInfo.blood}</p>
                                    <p><span className="font-semibold">Last Checkup:</span> {treatmentInfo.lastCheckup}</p>
                                    <p><span className="font-semibold">Number of Checkups:</span> {treatmentInfo.numberOfCheckup}</p>
                                    <p><span className="font-semibold">Patient:</span> {treatmentInfo.patientName}</p>
                                    <p><span className="font-semibold">ID:</span> {treatmentInfo.patientID}</p>
                                    <p><span className="font-semibold">Systolic BP:</span> {treatmentInfo.systolicBP} mm Hg</p>
                                    <p><span className="font-semibold">Diastolic BP:</span> {treatmentInfo.diastolicBP} mm Hg</p>
                                    <p><span className="font-semibold">Weight:</span> {treatmentInfo.weight} Kgs</p>
                                    <button onClick={() => setIsEditing(true)} className="px-3 py-1 mt-2 text-white bg-blue-500 rounded-md">Edit</button>
                                </div>
                            )}
                            {/* Systolic BP Analysis */}
                            <div className="mt-4">
                                <h3 className="mb-2 text-lg font-semibold">Systolic BP Analysis</h3>
                                <div className="flex space-x-4">
                                    <div>
                                        <p><span className="font-semibold">Low:</span> {treatmentInfo.systolicBPAnalysis.low}%</p>
                                        <p><span className="font-semibold">Normal:</span> {treatmentInfo.systolicBPAnalysis.normal}%</p>
                                        <p><span className="font-semibold">High:</span> {treatmentInfo.systolicBPAnalysis.high}%</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default Profile;
